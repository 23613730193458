/*
** qe - quantum engine interface
** this file generated Mon Oct 02 2023 21:25:11 GMT-0700 (Pacific Daylight Time)
** by the file SquishyElectron/quantumEngine/building/genExports.js
*/

let cwrap;
export const qe = {};

export function defineQEngineFuncs() {
	// eslint-disable-next-line no-restricted-globals
	cwrap = self.Module.cwrap;

	qe.main = cwrap('main', 'number', []);
	qe.startNewSpace = cwrap('startNewSpace', 'number', ['string']);
	qe.addSpaceDimension = cwrap('addSpaceDimension', null, ['number','number','number','number','string']);
	qe.completeNewSpace = cwrap('completeNewSpace', 'number', ['number']);
	qe.deleteFullSpace = cwrap('deleteFullSpace', null, ['number']);
	qe.getCppExceptionMessage = cwrap('getCppExceptionMessage', 'number', ['number']);
	qe.qSpace_dumpVoltage = cwrap('qSpace_dumpVoltage', 'number', ['number','string']);
	qe.wave_normalize = cwrap('wave_normalize', null, ['number']);
	qe.avatar_getViewBuffer = cwrap('avatar_getViewBuffer', 'number', ['number']);
	qe.avatar_loadViewBuffer = cwrap('avatar_loadViewBuffer', 'number', ['number']);
	qe.avatar_dumpViewBuffer = cwrap('avatar_dumpViewBuffer', null, ['number','string']);
	qe.grinder_initThreadIntegration = cwrap('grinder_initThreadIntegration', null, ['number','number','number']);
	qe.grinder_oneFrame = cwrap('grinder_oneFrame', null, ['number']);
	qe.grinder_askForFFT = cwrap('grinder_askForFFT', null, ['number']);
	qe.grinder_copyFromAvatar = cwrap('grinder_copyFromAvatar', null, ['number','number']);
	qe.grinder_copyToAvatar = cwrap('grinder_copyToAvatar', null, ['number','number']);

	// constants shared with C++
	qe.contDISCRETE = 0;
	qe.contWELL = 1;
	qe.contENDLESS = 2;
	qe.ERROR_RADIUS = 1e-12;
}

window.defineQEngineFuncs = defineQEngineFuncs;  // just in case
window.qe = qe;

export default qe;

